@mixin breakpoint($min: 0, $max: 0) {
    $query: "all" !default;
    @if $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; }
    @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; }
    @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; }
    @media #{$query} { @content; }
}

@mixin for-mobile() {
    @media only screen and (max-width: $sm - 1) { @content; }
}
@mixin not-mobile() {
    @media only screen and (min-width: $sm) { @content; }
}