// PRINT
// ---------------------------------------------------------------------------
@media print {
    body {
        color: #000;
    }
    a {
        &,
        &:link,
        &:visited {
            color: #000;
            text-decoration: none;
        }
    }
}