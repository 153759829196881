@mixin make-label($color) {
    background-color: rgba($color, .07);
    color: $color;
    &.is-secondary {
        background-color: transparent;
        color: $color;
        border-color: rgba($color, .3);
    }
    &.is-tertiary {
        background-color: transparent;
        color: $color;
    }
	& .close:hover {
        background-color: $color;
	}
}