// colours
$color-text: #5a4a4a;
$color-secondary: #5C4C4C;
$color-tertiary: #7B6A6A;
$color-disabled: #9E8C8C;
$color-focus: #F0616F;
$color-link: #9D51EA;

$color-target: #d63f46;

// border
$border-radius: 1px;

// form
$form-item-margin: 36px;

// label
$form-label-font-size: 0.71875rem; // 11.5px
$form-label-font-weight: 600;
$form-label-text-transform: uppercase;
$form-label-color-text: $color-text;