/*
	Kube Chicken Soup Theme 1.0
*/
@import "../vars";
@import "kube.theme.vars";
@import "../mixins";
@import "../modules";

header {
  background-color: #fff;
}

.button.custom-button {
  @include make-button($color-target, #fff);
}