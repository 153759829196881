// PROGRESS
// ---------------------------------------------------------------------------

@keyframes progress-bar-stripes {
	from { background-position: 40px 0; }
	to { background-position: 0 0; }
}

.kube-progress {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11000;
	width: 100%;
    background-color: $progress-background-box-color;

    & span {
    	@include striped;
    	@include animation(progress-bar-stripes 2s linear infinite);

        content: '';
        display: block;
        min-height: 8px;
        width: 100%;
    	height: 100%;
    	background-color: $progress-background-color;
    	background-size: 40px 40px;
    }
}


